import { FormEvent, useState } from "react";
import "./App.css";
import {
  types,
  PokeType,
  EffectivenessOptions,
  getRandomType,
  checkAnswersForType,
} from "./data/types";
import { typeIcons } from "./icons/typeIcons";

function App() {
  const [showResults, setShowResults] = useState<boolean>(false);

  const [currentType, setCurrentType] = useState<PokeType>(getRandomType());

  const [selectedWeakness, setSelectedWeakness] = useState<Array<number>>([]);
  const [selectedStrength, setSelectedStrength] = useState<Array<number>>([]);
  const [selectedImmuneTo, setSelectedImmuneTo] = useState<Array<number>>([]);

  const [selectedWeaknessFaults, setSelectedWeaknessFaults] = useState<
    Array<number>
  >([]);
  const [selectedStrengthFaults, setSelectedStrengthFaults] = useState<
    Array<number>
  >([]);
  const [selectedImmuneToFaults, setSelectedImmuneToFaults] = useState<
    Array<number>
  >([]);

  const checkIfButtonIsSelected = (
    effectivenessOption: string,
    id: number
  ): boolean => {
    switch (effectivenessOption) {
      case EffectivenessOptions.STRONG_AGAINST:
        return selectedStrength.includes(id);
      case EffectivenessOptions.WEAK_AGAINST:
        return selectedWeakness.includes(id);
      case EffectivenessOptions.IMMUNE_TO:
        return selectedImmuneTo.includes(id);
      default:
        return false;
    }
  };

  const handleTypeOptionClick = (
    effectivenessOption: string,
    selectedTypeId: number
  ) => {
    switch (effectivenessOption) {
      case EffectivenessOptions.STRONG_AGAINST:
        selectedStrength.includes(selectedTypeId)
          ? setSelectedStrength(
              selectedStrength.filter((a) => a !== selectedTypeId)
            )
          : setSelectedStrength([...selectedStrength, selectedTypeId]);
        break;

      case EffectivenessOptions.WEAK_AGAINST:
        selectedWeakness.includes(selectedTypeId)
          ? setSelectedWeakness(
              selectedWeakness.filter((a) => a !== selectedTypeId)
            )
          : setSelectedWeakness([...selectedWeakness, selectedTypeId]);
        break;

      case EffectivenessOptions.IMMUNE_TO:
        selectedImmuneTo.includes(selectedTypeId)
          ? setSelectedImmuneTo(
              selectedImmuneTo.filter((a) => a !== selectedTypeId)
            )
          : setSelectedImmuneTo([...selectedImmuneTo, selectedTypeId]);
        break;

      default:
        break;
    }
  };

  const checkAnswers = (e: FormEvent) => {
    e.preventDefault();

    setSelectedWeaknessFaults(
      checkAnswersForType(
        selectedWeakness,
        EffectivenessOptions.WEAK_AGAINST,
        currentType
      )
    );
    setSelectedStrengthFaults(
      checkAnswersForType(
        selectedStrength,
        EffectivenessOptions.STRONG_AGAINST,
        currentType
      )
    );
    setSelectedImmuneToFaults(
      checkAnswersForType(
        selectedImmuneTo,
        EffectivenessOptions.IMMUNE_TO,
        currentType
      )
    );

    setShowResults(true);
  };

  const reset = () => {
    setSelectedImmuneTo([]);
    setSelectedImmuneToFaults([]);
    setSelectedStrength([]);
    setSelectedStrengthFaults([]);
    setSelectedWeakness([]);
    setSelectedImmuneToFaults([]);
    setCurrentType(getRandomType());
    setShowResults(false);
  };

  return (
    <div className="App">
      <header>
        <h1>PokeTypeTrainer</h1>
      </header>

      {showResults ? (
        <section className="results-container">
          <h2>Results</h2>
          <span>
            Your type was{" "}
            <b
              style={{
                fontSize: "1.5rem",
                color: currentType.color,
              }}
            >
              {currentType.name}
            </b>
            , it is:
          </span>
          <div className="results">
            {Object.values(EffectivenessOptions).map(
              (effectivenessOption: string) => {
                let expectedAnswers: Array<number> = [];
                let givenAnswers: Array<number> = [];
                let faultyAnswers: Array<number> = [];
                switch (effectivenessOption) {
                  case EffectivenessOptions.WEAK_AGAINST:
                    expectedAnswers = currentType.weak_against;
                    givenAnswers = selectedWeakness;
                    faultyAnswers = selectedWeaknessFaults;
                    break;

                  case EffectivenessOptions.STRONG_AGAINST:
                    expectedAnswers = currentType.strong_against;
                    givenAnswers = selectedStrength;
                    faultyAnswers = selectedStrengthFaults;
                    break;

                  case EffectivenessOptions.IMMUNE_TO:
                    expectedAnswers = currentType.immune_to;
                    givenAnswers = selectedImmuneTo;
                    faultyAnswers = selectedImmuneToFaults;
                    break;

                  default:
                    expectedAnswers = currentType.weak_against;
                    givenAnswers = selectedWeakness;
                    faultyAnswers = selectedWeaknessFaults;
                    break;
                }

                return expectedAnswers.length !== 0 ? (
                  <div>
                    <h3>{effectivenessOption}</h3>
                    {expectedAnswers.map((answer) => {
                      const answerType: PokeType =
                        Object.values(types)[answer - 1];
                      return (
                        <div
                          className={
                            givenAnswers.includes(answer)
                              ? "typeOptionDisplay border-success "
                              : faultyAnswers.includes(answer)
                              ? "typeOptionDisplay border-faulty "
                              : ""
                          }
                          style={{
                            background: answerType.color,
                          }}
                        >
                          <img
                            width={16}
                            height={16}
                            src={typeIcons[answerType.name]}
                            alt={answerType.name}
                          />
                          <span>{answerType.name}</span>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  ""
                );
              }
            )}
          </div>
          <button
            className="playAgainButton"
            type="button"
            onClick={() => reset()}
          >
            play again
          </button>
        </section>
      ) : (
        ""
      )}

      {!showResults ? (
        <section>
          <form>
            <section className="typeDisplayContainer">
              <p>
                Your type is <br />
                <span style={{ color: currentType.color }}>
                  {currentType.name}
                </span>
              </p>
              <hr />
              <div>
                <button type="submit" onClick={(e) => checkAnswers(e)}>
                  Check
                </button>
                <button
                  type="button"
                  onClick={() => setCurrentType(getRandomType())}
                >
                  refresh
                </button>
              </div>
            </section>
            <div className="typeOptionInputsContainer">
              {Object.values(EffectivenessOptions).map(
                (effectivenessOption: string) => {
                  return (
                    <fieldset
                      key={effectivenessOption}
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <legend>{effectivenessOption}</legend>
                      <div
                        className="typeOptionInputs"
                        data-option={effectivenessOption}
                      >
                        {Object.values(types).map(
                          (currentPokeType: PokeType) => {
                            return (
                              <button
                                key={
                                  effectivenessOption +
                                  "-" +
                                  currentPokeType.name
                                }
                                onClick={() =>
                                  handleTypeOptionClick(
                                    effectivenessOption,
                                    currentPokeType.id
                                  )
                                }
                                className={
                                  checkIfButtonIsSelected(
                                    effectivenessOption,
                                    currentPokeType.id
                                  )
                                    ? "typeOptionButton btn-selected"
                                    : "typeOptionButton"
                                }
                                type="button"
                                style={{
                                  background: currentPokeType.color,
                                }}
                              >
                                <img
                                  width={16}
                                  height={16}
                                  src={typeIcons[currentPokeType.name]}
                                  alt={currentPokeType.name}
                                />
                                <span>{currentPokeType.name}</span>
                              </button>
                            );
                          }
                        )}
                      </div>
                    </fieldset>
                  );
                }
              )}
            </div>
          </form>
        </section>
      ) : (
        ""
      )}

      <footer>
        <p>Hope you enjoyed playing!</p>
        <a
          href="https://www.buymeacoffee.com/rubenwinant"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src="https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=rubenwinant&button_colour=FFDD00&font_colour=000000&font_family=Poppins&outline_colour=000000&coffee_colour=ffffff"
            alt="Buy me a coffee"
          />
        </a>
        <p>
          If you have any feedback, ideas for new features or wanna find out
          what i'm up to. Feel free to reach out!
          <br />
          <a
            href="http://www.x.com/ruben_winant"
            target="_blank"
            rel="noreferrer"
          >
            @ruben_winant
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;
