export interface PokeType {
  id: number;
  name: string;
  color: string;
  weak_against: Array<number>;
  strong_against: Array<number>;
  immune_to: Array<number>;
}

export enum EffectivenessOptions {
  WEAK_AGAINST = "Weak against",
  STRONG_AGAINST = "Strong against",
  IMMUNE_TO = "Immune to",
}

export const types: Record<number, PokeType> = {
  1: {
    id: 1,
    name: "Normal",
    color: "#A8A77A",
    weak_against: [2], // Fighting
    strong_against: [],
    immune_to: [8], // Ghost
  },
  2: {
    id: 2,
    name: "Fighting",
    color: "#C22E28",
    weak_against: [3, 14, 18], // Flying, Psychic, Fairy
    strong_against: [1, 6, 9, 15, 17], // Normal, Rock, Steel, Ice, Dark
    immune_to: [],
  },
  3: {
    id: 3,
    name: "Flying",
    color: "#A98FF3",
    weak_against: [13, 6, 15], // Electric, Rock, Ice
    strong_against: [12, 2, 7], // Grass, Fighting, Bug
    immune_to: [],
  },
  4: {
    id: 4,
    name: "Poison",
    color: "#A33EA1",
    weak_against: [5, 14], // Ground, Psychic
    strong_against: [12, 18], // Grass, Fairy
    immune_to: [],
  },
  5: {
    id: 5,
    name: "Ground",
    color: "#E2BF65",
    weak_against: [11, 15, 12], // Water, Ice, Grass
    strong_against: [6, 10, 13, 4, 9], // Rock, Fire, Electric, Poison, Steel
    immune_to: [13], // Electric
  },
  6: {
    id: 6,
    name: "Rock",
    color: "#B6A136",
    weak_against: [11, 12, 2, 5, 9], // Water, Grass, Fighting, Ground, Steel
    strong_against: [10, 3, 4, 1], // Fire, Flying, Poison, Normal
    immune_to: [],
  },
  7: {
    id: 7,
    name: "Bug",
    color: "#A6B91A",
    weak_against: [3, 6, 10], // Flying, Rock, Fire
    strong_against: [12, 14, 17], // Grass, Psychic, Dark
    immune_to: [],
  },
  8: {
    id: 8,
    name: "Ghost",
    color: "#735797",
    weak_against: [8, 17], // Ghost, Dark
    strong_against: [14, 13], // Psychic, Ghost
    immune_to: [1, 2], // Normal, Fighting
  },
  9: {
    id: 9,
    name: "Steel",
    color: "#B7B7CE",
    weak_against: [2, 10, 5], // Fighting, Fire, Ground
    strong_against: [6, 15, 18], // Rock, Ice, Fairy
    immune_to: [4], // Poison
  },
  10: {
    id: 10,
    name: "Fire",
    color: "#EE8130",
    weak_against: [11, 6], // Water, Rock
    strong_against: [12, 7, 15], // Grass, Bug, Ice
    immune_to: [],
  },
  11: {
    id: 11,
    name: "Water",
    color: "#6390F0",
    weak_against: [13, 12], // Electric, Grass
    strong_against: [10, 5, 6], // Fire, Ground, Rock
    immune_to: [],
  },
  12: {
    id: 12,
    name: "Grass",
    color: "#7AC74C",
    weak_against: [3, 4, 7, 10, 15], // Flying, Poison, Bug, Fire, Ice
    strong_against: [11, 5, 6], // Water, Ground, Rock
    immune_to: [],
  },
  13: {
    id: 13,
    name: "Electric",
    color: "#F7D02C",
    weak_against: [5], // Ground
    strong_against: [11, 3], // Water, Flying
    immune_to: [],
  },
  14: {
    id: 14,
    name: "Psychic",
    color: "#F95587",
    weak_against: [7, 8, 17], // Bug, Ghost, Dark
    strong_against: [2, 4], // Fighting, Poison
    immune_to: [],
  },
  15: {
    id: 15,
    name: "Ice",
    color: "#96D9D6",
    weak_against: [10, 2, 6, 9], // Fire, Fighting, Rock, Steel
    strong_against: [12, 5, 3, 16], // Grass, Ground, Flying, Dragon
    immune_to: [],
  },
  16: {
    id: 16,
    name: "Dragon",
    color: "#6F35FC",
    weak_against: [15, 16, 18], // Ice, Dragon, Fairy
    strong_against: [16], // Dragon
    immune_to: [],
  },
  17: {
    id: 17,
    name: "Dark",
    color: "#705746",
    weak_against: [2, 7, 18], // Fighting, Bug, Fairy
    strong_against: [8, 14], // Ghost, Psychic
    immune_to: [],
  },
  18: {
    id: 18,
    name: "Fairy",
    color: "#D685AD",
    weak_against: [4, 9], // Poison, Steel
    strong_against: [2, 17, 16], // Fighting, Dark, Dragon
    immune_to: [],
  },
};

export const getRandomType = (): PokeType => {
  const randomNumber =
    Math.floor(Math.random() * Object.keys(types).length) + 1;

  return types[randomNumber];
};

export const checkAnswersForType = (
  answers: Array<number>,
  type: string,
  currentPokeType: PokeType
): Array<number> => {
  let faults: Array<number> = [];
  let expectedAnswers: Array<number> = [];

  switch (type) {
    case EffectivenessOptions.WEAK_AGAINST:
      expectedAnswers = currentPokeType.weak_against;
      break;

    case EffectivenessOptions.STRONG_AGAINST:
      expectedAnswers = currentPokeType.strong_against;
      break;

    case EffectivenessOptions.IMMUNE_TO:
      expectedAnswers = currentPokeType.immune_to;
      break;

    default:
      return faults;
  }

  answers.forEach((answer) => {
    if (!expectedAnswers.includes(answer)) {
      faults.push(answer);
    }
  });

  expectedAnswers.forEach((expectedAnswer) => {
    if (!answers.includes(expectedAnswer)) {
      faults.push(expectedAnswer);
    }
  });

  return faults;
};
